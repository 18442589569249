<template>
  <div>
    <div class="error-panel">
      <a href="https://www.softechpark.com" class="sidebar-logo mg-b-40"><img src="/assets/img/thumb.png" alt=""></a>
      <h1 class="tx-28 tx-sm-36 tx-numeric tx-md-40 tx-semibold">404 Page Not Found</h1>
      <h4 class="tx-16 tx-sm-18 tx-md-24 tx-light mg-b-20 mg-md-b-30">Oopps. The page you were looking for doesn't exist.</h4>
      <p class="tx-12 tx-sm-13 tx-md-14 tx-color-04">You may have mistyped the address or the page may have moved.</p>
      <!-- But you can click Button below to go back to Dashboard -->

      <div class="">
        <a href="/"><button class="btn btn-primary" type="button">Go Back</button></a>
      </div>
    </div><!-- error-panel -->
  </div>
  
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {};
  },
};
</script>
